import React from "react"
import { getLocalizedURL } from "@src/helper"
// Style Imports
// import '../../registration/styles.scss'

// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import SignUpForm from "@components/form/Form.Signup.jsx"

const Registration = () => (
    <Layout addedClass="page--registration">
        <Seo title="Registro | LUPKYNIS&reg; (voclosporina)" 
        description="Inscríbase para conocer lo último sobre LUPKYNIS, obtenga información sobre cómo vivir con nefritis lúpica y escuche sobre Aurinia Alliance®, un programa de apoyo personalizado. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA." 
        canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('registration','es')} />
        <Hero addedClass="bg-coral hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">Registro</h1>
                        <h2>Mantenerse conectado</h2>
                        <p>Inscríbase para estar al tanto de lo más reciente sobre LUPKYNIS, obtenga información sobre cómo vivir con nefritis lúpica y escuche sobre Aurinia Alliance<sup>&reg;</sup>, un programa de apoyo personalizado.</p>
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                    </div>
                </div>
            </div>
        </Hero>
        <section id="SignUp" className="bg-purple">
            <div className="container">
                <h2>Cuéntenos sobre usted</h2>
                <p className="legend">* = campo obligatorio</p>
                <SignUpForm />
            </div>
        </section>

    </Layout>
)

export default Registration